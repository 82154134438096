import * as React from "react"
// import { Link } from "gatsby"
import Welcomevisits from "../../pages/welcomevisits/welcomevisit"

const Welcomevisit = () => (
  <>
    <Welcomevisits />
  </>
)

export default Welcomevisit
